html, body {
  overflow-x: hidden;
}

mark {
  color: #000;
  border: 1px solid #f68411;
  border: 50%;
  margin: 0;
  padding: 0;
}

#img {
  margin-left: auto;
}

pre {
  text-align: center;
  color: #282828;
  border: 5px solid #63ff6b;
  border-radius: 20px;
}

.navbar-nav .nav-link {
  font-size: 1rem;
}

#img {
  margin-right: 30px;
}

.col-xs-2 {
  padding-left: 20px;
}

.col-xs-8 {
  padding-right: 20px;
}

.navbar-toggler:focus {
  color: #f0f8ff;
}

.nav-link, .navbar-brand {
  color: #f0f8ff;
  font-size: 1.7rem;
  text-align: center !important;
}

.nav-item:hover {
  color: #212529;
  background-color: #1f6962;
  border-radius: 70%;
  transition: all .8s;
}

.navbar-toggler {
  transition: all .6s !important;
}

footer .darkFooter {
  color: #fff !important;
  background-color: #212529 !important;
}

.blog-section {
  margin-top: 50px;
}

#margg {
  margin-top: 0;
}

.blog-box {
  height: 100%;
  border: 1px solid #eaeaea;
  box-shadow: 0 3px 10px 5px #0000001a;
}

.blog-box .card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.blog-box .card-body .card-title {
  font-family: Arial;
  font-size: 19px;
  font-weight: 600;
}

.nav > li > a:hover {
  color: #45a2ff;
}

section.blog-detail .blog-box {
  box-shadow: none;
  border: 0;
  margin-right: 20px;
  padding: 1rem .5rem;
}

section.blog-detail .blog-box .card-img-top {
  height: 100%;
}

section.blog-detail .blog-box .card-body .card-title {
  font-size: 25px;
}

section.blog-detail .blog-box .card-body .blog-desc p {
  text-align: justify;
}

.blog-list-heading {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}

.blog-listing {
  border-bottom: 1px solid #ccc;
  flex-flow: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
}

.blog-listing .img {
  width: 90px;
  flex: none;
}

.blog-listing .value {
  width: calc(100% - 90px);
  text-align: justify;
  flex: none;
  padding-left: 10px;
  font-size: 14px;
}

.blog-listing .value strong {
  font-size: 17px;
  line-height: 17px;
  display: inline-block;
}

.sticky-sidebar {
  position: sticky;
  top: 40px;
}

h2 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: -20px;
  padding: 1rem 1rem 0;
  font-weight: 500;
}

.navbar-toggler {
  width: 40px;
  height: 40px;
  background-color: #0000;
  border: 2px solid #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

.navbar-toggler-icon {
  width: 25px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.navbar-toggler-icon:before, .navbar-toggler-icon:after, .navbar-toggler-icon div {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #009cc4;
  transition: background-color .3s, transform .3s;
  position: absolute;
  left: 0;
}

.navbar-toggler-icon:before {
  top: 0;
}

.navbar-toggler-icon div {
  top: 7px;
}

.navbar-toggler-icon:after {
  bottom: 0;
}

.navbar-toggler:hover {
  background-color: #d3d3d3;
  border: 2px solid #d3d3d3;
  border-radius: 5px;
}

.navbar-toggler:hover .navbar-toggler-icon:before, .navbar-toggler:hover .navbar-toggler-icon:after, .navbar-toggler-icon:hover div {
  background-color: #000;
}

a {
  color: #109b6c;
  font-weight: 700;
  text-decoration: none;
}

.brandingLogo {
  display: none !important;
}

.col-xs-2, .col-xs-8 {
  flex: 1;
}

label {
  font-size: 1rem;
}

#mc_embed_signup .helper_text {
  display: none !important;
}

@media screen and (max-width: 431px) {
  .navbar-brand {
    white-space: nowrap;
    font-size: 1.25rem;
  }

  #img {
    height: 30px;
  }

  .navbar-toggler {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 413px) {
  .navbar-brand {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 1.1rem;
  }

  .navbar-toggler {
    padding: .25rem .5rem;
  }

  #img {
    height: 28px;
  }
}

@media (max-width: 390px) {
  .navbar-collapse {
    text-align: center;
  }

  .navbar-brand {
    margin-right: 2px;
    font-size: 1rem;
  }

  .navbar-toggler {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .blog-box .card-img-top {
    height: 100%;
  }

  section.blog-detail .blog-box {
    margin-right: 0;
  }

  .sticky-sidebar {
    width: 90%;
    margin-left: 5%;
  }

  footer {
    width: 100%;
    font-size: .75rem;
  }

  img {
    width: 50%;
  }

  h5 {
    font-size: 22px !important;
  }

  .nav-link, .navbar-brand {
    text-align: center;
    font-size: 1rem !important;
  }

  label {
    font-size: .7rem;
  }

  #mc_embed_signup {
    width: 95% !important;
  }

  #onlyh {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 2120px) and (min-width: 900px) {
  #img {
    margin-right: 300px;
  }

  .navbar-brand {
    margin-left: 10px;
  }
}

@media screen and (max-width: 435px) {
  .navbar-brand {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 1.2rem;
  }

  #img {
    height: 70px;
    width: auto;
    margin-right: 10px;
  }

  .navbar-toggler {
    padding: .25rem .5rem;
    font-size: 1.1rem;
  }

  .navbar-collapse {
    text-align: center;
  }
}

@media screen and (max-width: 390px) {
  .navbar-brand {
    margin-right: 2px;
    font-size: 1rem;
  }

  #img {
    height: 70px;
    width: auto;
  }

  .navbar-toggler {
    padding: .2rem .4rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 993px) and (min-width: 900px) {
  .navbar-brand {
    white-space: nowrap;
    margin-right: 15px;
    font-size: 1.4rem;
  }

  #img {
    height: 150px;
    width: auto;
    margin-right: 200px;
  }

  .navbar-toggler {
    padding: 2.5rem .95rem;
    font-size: 1.2rem;
  }

  .navbar-nav .nav-link {
    font-size: .95rem;
  }

  .navbar-collapse {
    text-align: right;
  }
}

/*# sourceMappingURL=blog-detail.c9885f48.css.map */
