html,
body {
  overflow-x: hidden;
}

mark {
  color: black;
  border: 1px solid rgb(246, 132, 17);
  border: 50%;
  padding: 0px;
  margin: 0px;
}

#img {
  margin-left: auto;
}

pre {
  border-radius: 20px;
  text-align: center;
  color: rgb(40, 40, 40);
  border: 5px solid rgb(99, 255, 107);
}

.navbar-nav .nav-link {
  font-size: 1rem;
}

#img {
  margin-right: 30px;
}

/* Add some space between the logo and the word */
.col-xs-2 {
  padding-left: 20px;
}

/* Move the word closer to the dropdown */
.col-xs-8 {
  padding-right: 20px;
}

.navbar-toggler:focus {
  color: aliceblue;
}

.nav-link,
.navbar-brand {
  color: aliceblue;
  font-size: 1.7rem;
  text-align: center !important;
}

.nav-item:hover {
  background-color: #1f6962;
  color: #212529;
  border-radius: 70%;
  transition: all 800ms;
}

.navbar-toggler {
  transition: all 600ms !important;
}

footer .darkFooter {
  background-color: #212529 !important;
  color: white !important;
}

.blog-section {
  margin-top: 50px;
}

#margg {
  margin-top: 0px;
}

.blog-box {
  border: 1px solid #eaeaea;
  box-shadow: 0 3px 10px 5px rgba(0 0 0 / 10%);
  height: 100%;
}

.blog-box .card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.blog-box .card-body .card-title {
  font-size: 19px;
  font-family: 'Arial';
  font-weight: 600;
}

.nav > li > a:hover {
  color: #45a2ff;
}

section.blog-detail .blog-box {
  box-shadow: none;
  border: 0px;
  padding: 1rem 0.5rem;
  margin-right: 20px;
}

section.blog-detail .blog-box .card-img-top {
  height: 100%;
}

section.blog-detail .blog-box .card-body .card-title {
  font-size: 25px;
}

section.blog-detail .blog-box .card-body .blog-desc p {
  text-align: justify;
}

.blog-list-heading {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.blog-listing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.blog-listing .img {
  flex: 0 0 auto;
  width: 90px;
}

.blog-listing .value {
  flex: 0 0 auto;
  width: calc(100% - 90px);
  padding-left: 10px;
}

.blog-listing .value {
  font-size: 14px;
  text-align: justify;
}

.blog-listing .value strong {
  line-height: 17px;
  display: inline-block;
  font-size: 17px;
}

.sticky-sidebar {
  position: sticky;
  top: 40px;
}

h2 {
  padding: 1rem 1rem 0rem 1rem;
  margin-top: 40px;
  margin-bottom: -20px;
  font-weight: 500;
  text-align: center;
}
/* Basic styles for the hamburger button */
.navbar-toggler {
  border: 2px solid white; /* Light border around the button */
  background-color: transparent; /* Transparent background */
  border-radius: 5px; /* Rounded corners */
  padding: 5px; /* Padding for space around the icon */
  width: 40px; /* Width of the button */
  height: 40px; /* Height of the button */
  display: flex; /* Center the bars inside */
  justify-content: center; /* Horizontally center the bars */
  align-items: center; /* Vertically center the bars */
}

/* Hamburger icon styling (for the bars) */
.navbar-toggler-icon {
  width: 25px; /* Width of the hamburger bars */
  height: 18px; /* Total height for the three bars */
  position: relative;
  display: inline-block;
}

/* Top, middle, and bottom bars for the hamburger */
.navbar-toggler-icon::before,
.navbar-toggler-icon::after,
.navbar-toggler-icon div {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px; /* Thickness of the bars */
  background-color: rgb(0, 156, 196); /* Set bars to a lighter color */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Top bar */
.navbar-toggler-icon::before {
  top: 0; /* Position the top bar */
}

/* Middle bar */
.navbar-toggler-icon div {
  top: 7px; /* Position the middle bar */
}

/* Bottom bar */
.navbar-toggler-icon::after {
  bottom: 0; /* Position the bottom bar */
}

/* Hover effect for the button */
.navbar-toggler:hover {
  background-color: lightgray; /* Light background on hover */
  border: 2px solid lightgray; /* Light border on hover */
  border-radius: 5px;
}

/* Change the bars to a darker color on hover */
.navbar-toggler:hover .navbar-toggler-icon::before,
.navbar-toggler:hover .navbar-toggler-icon::after,
.navbar-toggler-icon:hover div {
  background-color: black; /* Dark bars on hover */
}

a {
  color: rgb(16, 155, 108);
  font-weight: 700;
  text-decoration: none;
}

.brandingLogo {
  display: none !important;
}

/* Add some flex properties to make the layout more flexible */
.col-xs-2,
.col-xs-8 {
  flex: 1;
}

label {
  font-size: 1rem;
}

#mc_embed_signup .helper_text {
  display: none !important;
}

/* Fixing the issue below 431px */
@media screen and (max-width: 431px) {
  .navbar-brand {
    font-size: 1.25rem; /* Adjust the brand size */
    white-space: nowrap; /* Prevent the brand text from breaking into multiple lines */
  }

  #img {
    height: 30px; /* Reduce logo size */
  }

  .navbar-toggler {
    font-size: 1.25rem;
  }
}

/* Further adjust below 413px to ensure text and dropdown stay aligned */
@media screen and (max-width: 413px) {
  .navbar-brand {
    font-size: 1.1rem; /* Further reduce the font size */
    margin-right: 5px; /* Reduce the space between the brand and dropdown */
    white-space: nowrap; /* Ensure no line breaks */
  }

  .navbar-toggler {
    padding: 0.25rem 0.5rem; /* Adjust padding */
  }

  #img {
    height: 28px; /* Further reduce logo size */
  }
}

/* Optional: Fine-tune alignment and size for very small screens */
@media (max-width: 390px) {
  .navbar-collapse {
    text-align: center; /* Center navbar items */
  }

  .navbar-brand {
    font-size: 1rem; /* Shrink the text size further */
    margin-right: 2px; /* Adjust the space */
  }

  .navbar-toggler {
    font-size: 1rem; /* Adjust the hamburger icon */
  }
}

@media screen and (max-width: 767px) {
  .blog-box .card-img-top {
    height: 100%;
  }

  section.blog-detail .blog-box {
    margin-right: 0;
  }

  .sticky-sidebar {
    width: 90%;
    margin-left: 5%;
  }

  footer {
    width: 100%;
    font-size: 0.75rem;
  }

  img {
    width: 50%;
  }

  h5 {
    font-size: 22px !important;
  }

  .nav-link,
  .navbar-brand {
    text-align: center;
    font-size: 1rem !important;
  }

  label {
    font-size: 0.7rem;
  }

  #mc_embed_signup {
    width: 95% !important;
  }

  #onlyh {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 2120px) and (min-width: 900px) {
  #img {
    margin-right: 300px; /* Adjust the value as needed */
  }

  .navbar-brand {
    margin-left: 10px; /* Adjust if the text needs to move closer to the logo */
  }
}
/* For screens smaller than 435px */
@media screen and (max-width: 435px) {
  .navbar-brand {
    font-size: 1.2rem; /* Reduce the font size */
    margin-right: 5px; /* Reduce space between logo and text */
    white-space: nowrap; /* Prevent the text from wrapping */
  }

  #img {
    height: 70px; /* Prevent the image from stretching */
    width: auto; /* Maintain the aspect ratio of the image */
    margin-right: 10px; /* Adjust spacing to ensure the image doesn't push the dropdown */
  }

  .navbar-toggler {
    padding: 0.25rem 0.5rem; /* Adjust padding of the dropdown icon */
    font-size: 1.1rem; /* Reduce the size of the hamburger icon */
  }

  .navbar-collapse {
    text-align: center; /* Align navbar items in the center if needed */
  }
}

/* Optional: Fine-tune for even smaller screens (below 390px) */
@media screen and (max-width: 390px) {
  .navbar-brand {
    font-size: 1rem; /* Further reduce text size */
    margin-right: 2px;
  }

  #img {
    height: 70px; /* Shrink the logo more if necessary */
    width: auto;
  }

  .navbar-toggler {
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
  }
}

/* For screens between 993px and 900px */
@media screen and (max-width: 993px) and (min-width: 900px) {
  .navbar-brand {
    font-size: 1.4rem; /* Reduce the font size for the text */
    margin-right: 15px; /* Reduce space between logo and text */
    white-space: nowrap; /* Ensure the text stays in one line */
  }

  #img {
    height: 150px; /* Slightly reduce the logo size */
    width: auto; /* Maintain the aspect ratio of the logo */
    margin-right: 200px; /* Reduce margin to save space */
  }

  .navbar-toggler {
    font-size: 1.2rem; /* Adjust the size of the hamburger icon */
    padding: 2.5rem 0.95rem; /* Fine-tune padding */
  }

  /* Ensure navbar links are not wrapping */
  .navbar-nav .nav-link {
    font-size: 0.95rem; /* Reduce font size of the links */
  }

  .navbar-collapse {
    text-align: right; /* Align navbar items to the right */
  }
}
